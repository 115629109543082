import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './logo.svg';
import postings from './postings.svg';
import insights from './insights.svg';
import epk from './epk.svg';
import email from './emailTemplates.svg';
import calendar from './calendar.svg';
import peopleIcon from './peopleIcon.svg';
import starOutline from './starOutline.svg';
import landing_jora from './landing-page-jora.svg';
import landing_second_jora from './landing-page-second.svg';
import landing_third_jora from './landing-page-third.svg';
import './App.css';

const PAGE_STATE = {
  HOME: 'HOME',
  ARTISTS: 'ARTISTS',
  MIC: 'MIC',
  LINEUP: 'LINEUP',
};

export default function App()
{
  const [activeButton, setActiveButton] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [activePage, setActivePage] = useState(PAGE_STATE.HOME);
  const navigate = useNavigate();

  const stayTunedRef = useRef(null);
  const home = useRef(null);
  const features = useRef(null);

  const handleScrollTo = (ref) =>
  {
    if (activePage === PAGE_STATE.HOME)
    {
      const headerOffset = 80;
      const elementPosition = ref.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleNavigation = (page) =>
  {
    setActivePage(page);
    if (page === PAGE_STATE.ARTISTS)
    {
      navigate('/event');
    } else if (page === PAGE_STATE.MIC)
    {
      navigate('/openmic');
    }
  };

  return (
    <div className="bg-white w-full h-full overflow-y-auto">
      {/* Navigation Buttons */}
      <div className='min-h-screen w-full flex flex-col'>
        <header className="bg-white shadow-md fixed top-0 w-full z-50">
          <div className="container mx-auto flex justify-between items-center py-5 sm:px-5 px-3">
            <div className='flex gap-2.5'>
              <img src={logo} alt='' className='w-auto h-[33px]' />
              <h1 className="sm:text-2xl text-lg font-bold">Jora</h1>
            </div>
            <button
              className="lg:hidden text-gray-700"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              ☰
            </button>
            <nav
              className={`${menuOpen ? 'flex' : 'hidden'
                } flex-col bg-white lg:shadow-none shadow-md space-y-3 lg:flex lg:flex-row lg:space-y-0 lg:space-x-6 items-start lg:items-center text-xs lg:text-sm absolute top-full left-0 w-full lg:static lg:w-auto lg:bg-transparent py-5 lg:py-0 justify-start lg:justify-center px-10 lg:px-0`}
            >
              <button
                onClick={() =>
                {
                  setActivePage(PAGE_STATE.HOME);
                  handleScrollTo(features);
                }}
                className="text-gray-700 hover:text-[#4F46E5]"
              >
                Features
              </button>
              <div className="hidden h-2 w-2 rounded-full bg-gray-700 lg:block" />
              <button className="text-gray-700 hover:text-[#4F46E5]"
                onClick={() => handleNavigation(PAGE_STATE.ARTISTS)}
              >Events</button>
              <button className="text-gray-700 hover:text-[#4F46E5]"
                onClick={() => handleNavigation(PAGE_STATE.MIC)}
              >
                Open Mic Sign Up
              </button>
              {/* <button className="text-gray-700 hover:text-[#4F46E5]"
                onClick={() => setActivePage(PAGE_STATE.LINEUP)}
              >
                Artists Line Up
              </button> */}
              <button
                onClick={() =>
                {
                  setActivePage(PAGE_STATE.HOME);
                  handleScrollTo(stayTunedRef);
                }}
                className="bg-[#4F46E5] text-white px-4 py-2 rounded-md hover:bg-purple-700 transition"
              >
                Sign up for beta
              </button>
            </nav>
          </div>
        </header>

        {/* Landing Section */}
        {/* {activePage === PAGE_STATE.ARTISTS && <Navigate to="/event" />} */}
        {/* {activePage === PAGE_STATE.LINEUP && <LineUp />} */}
        {/* {activePage === PAGE_STATE.MIC && <Navigate to="/openmic" />} */}
        {activePage === PAGE_STATE.HOME && (
          <div ref={home} className="flex-grow flex justify-center w-full" style={{ backgroundImage: `url(${landing_jora})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
            <div className='flex items-center justify-center w-full lg:pt-32' style={{
              background: 'linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 1) 100%)'
            }}>
              <div className='flex flex-col gap-y-6 justify-center lg:justify-start items-center z-20 h-full w-full px-4 md:w-[75%] xl:w-[60%] 2xl:w-[60%]'>
                <p className='uppercase text-[#64748B] text-sm text-center font-bold'>Connect, perform, and grow with Jora.</p>
                <p className='text-black font-semibold text-center' style={{ fontSize: 'clamp(20px, 2vw + 1vh, 54.4px)' }}
                >Community based platform for live music—your one-stop shop for all live event needs</p>
                <p className='text-[#3F4654] text-sm sm:text-[16px] md:text-[14px] lg:text-[14px] xl:text-[16px] font-normal text-center'>Create a profile, share your music, and find shows, headliners, or collaborators with ease. Manage bookings, ticketing, and touring all in one place, with real-time insights at your fingertips.</p>
                <div className='flex gap-x-5 font-semibold'>
                  <button onClick={() => handleScrollTo(stayTunedRef)} className="bg-[#4F46E5] text-white text-xs sm:text-[14px] px-5 py-3 rounded-md hover:bg-purple-700 transition">
                    Sign up for artist beta
                  </button>
                  {/* <button onClick={() => handleScrollTo(stayTunedRef)} className="bg-white text-black text-xs sm:text-[14px] px-5 py-3 rounded-md">
                  Sign up for artist beta
                </button> */}
                </div>
              </div>
            </div>
            <div className="absolute bottom-0 flex justify-center z-20">
              <img src={landing_third_jora} alt="" className="h-auto w-[90%] md:w-[70%] lg:w-[50%] xl:w-[60%] max-w-full" />
            </div>
            <div className="absolute bottom-0 left-0 w-full z-10">
              <img src={landing_second_jora} alt="" className="w-full h-[40vh] sm:h-[50vh] md:h-[60vh] lg:h-full" />
            </div>
          </div>
        )}
      </div>
      {/* First Section */}
      {activePage === PAGE_STATE.HOME && (
        <div className="py-20 px-[20px] md:px-[40px] lg:px-[120px]">
          <h2 className="font-semibold text-left w-full md:w-[50%] pb-10" style={{ fontSize: 'clamp(20px, 2vw + 1vh, 54.4px)' }}>Everything you need to elevate your live music journey.</h2>
          <div className='flex flex-col md:flex-row w-full justify-between'>
            <div className='flex flex-col items-start w-full md:w-[27%] gap-y-2.5 pb-10 md:pb-0'>
              <button className='p-2.5 bg-white shadow-md rounded-lg'>
                <img src={peopleIcon} alt="" className="" />
              </button>
              <p className="text-left mt-10 text-[18px] font-bold uppercase">Artists</p>
              <p className="text-base lg:text-md xl:text-lg text-left mb-10 text-[#555] h-auto md:h-[200px]">
                Create targeted show postings to connect with venues based on genre and location. Our easy-to-use calendar will streamline scheduling, with event analytics coming soon.</p>
              <button className="bg-[#4F46E5] text-white px-4 py-2 rounded-md hover:bg-purple-700 transition" onClick={() => handleScrollTo(features)}>
                Learn More
              </button>
            </div>
            <div className='flex flex-col w-full md:w-[27%] items-start gap-y-2.5 pb-10 md:pb-0'>
              <button className='p-2.5 bg-white shadow-md rounded-lg'>
                <img src={peopleIcon} alt="" className="" />
              </button>
              <p className="text-left mt-10 text-[18px] font-bold">VENUES</p>
              <p className="text-base lg:text-md xl:text-lg text-left mb-10 text-[#555] h-auto md:h-[200px]">
                Create show postings that connect with artists based on genre and location. Use our native calendar for easy scheduling, with event analytics coming soon.</p>
              <button className="bg-[#4F46E5] text-white px-4 py-2 rounded-md hover:bg-purple-700 transition" onClick={() => handleScrollTo(features)}>
                Learn More
              </button>
            </div>
            <div className='flex flex-col w-full md:w-[27%] items-start gap-y-2.5'>
              <button className='p-2.5 bg-white shadow-md rounded-lg'>
                <img src={peopleIcon} alt="" className="" />
              </button>
              <p className="text-left mt-10 text-[18px] font-bold uppercase">Manager/Agent</p>
              <p className="text-base lg:text-md xl:text-lg text-left text-[#555]">Coming Soon!</p>
            </div>
          </div>
          <img src={starOutline} alt='' className='relative bottom-0 ml-auto h-auto w-[9%] md:w-[5%] xl:w-[3%]' />
        </div>
      )}
      {/* Second Section */}
      {activePage === PAGE_STATE.HOME && (
        <div className="w-full pb-20 px-[20px] md:px-[40px] lg:px-[120px]">
          <div className='rounded-xl w-full flex flex-col items-center'
            style={{
              background: `
            linear-gradient(to bottom right, rgba(255, 175, 204, 0.20) 0%, rgba(184, 192, 255, 0.30) 50%) bottom right / 50% 50% no-repeat,
            linear-gradient(to bottom left, rgba(255, 175, 204, 0.20) 0%, rgba(184, 192, 255, 0.30) 50%) bottom left / 50% 50% no-repeat,
            linear-gradient(to top left, rgba(255, 175, 204, 0.20) 0%, rgba(184, 192, 255, 0.30) 50%) top left / 50% 50% no-repeat,
            linear-gradient(to top right, rgba(255, 175, 204, 0.20) 0%, rgba(184, 192, 255, 0.30) 50%) top right / 50% 50% no-repeat
          `
            }}>
            <h2 className="font-semibold text-center w-[90%] md:w-[57%] pt-[80px]" style={{ fontSize: 'clamp(20px, 2vw + 1vh, 54.4px)' }}>Effortlessly manage your bookings and schedule in one place.</h2>
            <p className="text-base lg:text-md xl:text-lg text-center w-[95%] py-[51px] md:w-[70%] text-[#555]">
              Easily organize your events with our intuitive calendar and booking management system. Keep track of every show, rehearsal, and deadline, all from one central hub.
            </p>
            <img src={calendar} alt='calendar' className='w-[90%] md:w-[60%] h-auto pb-[60px]' />
          </div>
        </div>
      )}
      {/* Features Section */}
      {/* Feature One */}
      {activePage === PAGE_STATE.HOME && (
        <div ref={features} className="w-full pb-48 md:pb-20 px-[20px] md:px-[40px] lg:px-[120px]">
          <div className='flex flex-col md:flex-row w-full justify-between gap-x-[100px] gap-y-[50px]'>
            <div className='flex justify-center w-full md:w-2/5'>
              <img src={postings} alt='' />
            </div>
            <div className='w-full md:w-3/5 flex flex-col justify-center '>
              <h2 className="font-semibold text-center md:text-left w-full pb-5" style={{ fontSize: 'clamp(20px, 2vw + 1vh, 54.4px)' }}>Targeted Show Postings / Opportunities <span className='text-xs uppercase bg-gradient-to-r from-[#4F46E5] via-[#7C3AED] to-[#D946EF] bg-clip-text text-transparent'>beta</span></h2>
              <p className="text-base lg:text-md xl:text-lg text-center md:text-left text-[#555]">
                Create and browse show opportunities tailored to your specific needs. Whether you're a venue looking for the perfect artist or an artist searching for the right gig, our platform connects you with opportunities based on genre, location, and more, ensuring the best match for every event.
              </p>
            </div>
          </div>
        </div>
      )}
      {/* Feature Two */}
      {activePage === PAGE_STATE.HOME && (
        <div className="w-full pb-48 md:pb-20 px-[20px] md:px-[40px] lg:px-[120px]">
          <div className='flex flex-col md:flex-row w-full justify-between gap-x-[100px] gap-y-[50px]'>
            <div className='w-full md:w-3/5 flex justify-center order-first md:order-last'>
              <img src={insights} alt='' />
            </div>
            <div className='flex flex-col justify-center w-full md:w-2/5'>
              <h2 className="font-semibold text-center md:text-left w-full pb-5" style={{ fontSize: 'clamp(20px, 2vw + 1vh, 54.4px)' }}>Event Insights & Analytics <span className='text-xs uppercase bg-gradient-to-r from-[#4F46E5] via-[#7C3AED] to-[#D946EF] bg-clip-text text-transparent'>beta</span></h2>
              <p className="text-base lg:text-md xl:text-lg text-center md:text-left text-[#555]">
                Gain valuable insights into your performances and events with real-time analytics. Artists can track audience engagement and performance metrics, while venues can analyze show attendance and trends to make data-driven decisions. Elevate your strategy with the power of detailed event analytics.
              </p>
            </div>
          </div>
        </div>
      )}
      {/* Feature Three */}
      {activePage === PAGE_STATE.HOME && (
        <div className="w-full pb-48 md:pb-20 px-[20px] md:px-[40px] lg:px-[120px]">
          <div className='flex flex-col md:flex-row w-full justify-between gap-x-[100px] gap-y-[50px]'>
            <div className='flex justify-center w-full md:w-2/5'>
              <img src={epk} alt='' />
            </div>
            <div className='w-full md:w-3/5 flex flex-col justify-center '>
              <h2 className="font-semibold text-center md:text-left w-full pb-5" style={{ fontSize: 'clamp(20px, 2vw + 1vh, 54.4px)' }}>EPK Management <span className='text-xs uppercase bg-gradient-to-r from-[#4F46E5] via-[#7C3AED] to-[#D946EF] bg-clip-text text-transparent'>beta</span></h2>
              <p className="text-base lg:text-md xl:text-lg text-center md:text-left text-[#555]">
                Easily create and manage your Electronic Press Kit (EPK) to showcase your music, bio, photos, and more. With Jora, keep all your essential promotional materials in one place, ready to share with venues, promoters, and collaborators to land your next big opportunity.
              </p>
            </div>
          </div>
        </div>
      )}
      {/* Feature Four */}
      {activePage === PAGE_STATE.HOME && (
        <div className="w-full pb-20 px-[20px] md:px-[40px] lg:px-[120px]">
          <div className='flex flex-col md:flex-row w-full justify-between gap-x-[100px] gap-y-[50px]'>
            <div className='w-full md:w-3/5 flex justify-center order-first md:order-last'>
              <img src={email} alt='' />
            </div>
            <div className='flex flex-col justify-center w-full md:w-2/5'>
              <h2 className="font-semibold text-center md:text-left w-full pb-5" style={{ fontSize: 'clamp(20px, 2vw + 1vh, 54.4px)' }}>Email Templates <span className='text-xs uppercase bg-gradient-to-r from-[#4F46E5] via-[#7C3AED] to-[#D946EF] bg-clip-text text-transparent'>beta</span></h2>
              <p className="text-base lg:text-md xl:text-lg text-center md:text-left text-[#555]">
                Streamline your outreach with customizable email templates. Easily send professional, pre-written emails to venues for show applications, saving you time while staying connected to new opportunities.
              </p>
            </div>
          </div>
        </div>
      )}
      {/* Subscription Section */}
      {activePage === PAGE_STATE.HOME && (
        <div ref={stayTunedRef} className="w-full pb-20 px-[20px] md:px-[40px] lg:px-[120px]">
          <div className="bg-[#303030] rounded-xl text-center py-[83px] px-5">
            <h2 className="font-semibold text-white text-center w-full pb-5" style={{ fontSize: 'clamp(20px, 2vw + 1vh, 54.4px)' }}>Stay Tuned</h2>
            <p className="text-base lg:text-md xl:text-lg text-center text-white">
              Be the first to experience Jora! Subscribe to our beta and join the movement to transform live music collaboration. Get early access and help shape the future of the platform.
            </p>
            <form id='form'
              name='subscribe'
              method='post'
            >
              {/* Netlify form handling hidden field */}
              <input type='hidden' name='form-name' value='subscribe' />
              <input type='hidden' name='beta-type' value={activeButton} />
              <div className="mt-5 text-white flex flex-col space-y-4 sm:space-y-0 sm:flex-row justify-center sm:space-x-6 px-12">
                <button
                  type='button'
                  onClick={() => setActiveButton('Artist Beta')}
                  className={`text-base lg:text-md xl:text-lg px-6 py-3 rounded-xl border ${activeButton === 'Artist Beta' ? 'bg-[#FFF] border-transparent shadow-[inset_0_4px_8px_rgba(0,0,0,0.6)] text-black' : 'bg-transparent border-black'}`}
                >
                  Artist Beta
                </button>
                <button
                  type='button'
                  onClick={() => setActiveButton('Venue Beta')}
                  className={`text-base lg:text-md xl:text-lg px-6 py-3 rounded-xl border ${activeButton === 'Venue Beta' ? 'bg-[#FFF] border-transparent shadow-[inset_0_4px_8px_rgba(0,0,0,0.6)] text-black' : 'bg-transparent border-black'}`}
                >
                  Venue Beta
                </button>
                <button
                  type='button'
                  onClick={() => setActiveButton('Manager/Agent')}
                  className={`text-base lg:text-md xl:text-lg px-6 py-3 rounded-xl border ${activeButton === 'Manager/Agent' ? 'bg-[#FFF] border-transparent shadow-[inset_0_4px_8px_rgba(0,0,0,0.6)] text-black' : 'bg-transparent border-black'}`}
                >
                  Manager/Agent
                </button>
              </div>
              <div className="my-5">
                <input
                  placeholder='Enter your email'
                  type='email'
                  id='email'
                  name='email'
                  required
                  className="text-base lg:text-md xl:text-lg bg-[#f6f5fd] w-[80%] md:w-[50%] border border-black px-4 py-2 text-black rounded-full text-center"
                />
              </div>
              <button type='submit' className="text-base lg:text-md xl:text-lg bg-[#FFF] border-transparent text-black px-6 py-3 mt-4 rounded-xl">Subscribe</button>
            </form>
          </div>
        </div>
      )}
      <div className="w-full px-[20px] lg:px-[120px]">
        <div className="flex flex-col text-center py-[83px] px-5">
          <div className='flex flex-col sm:flex-row items-center border-b pb-5 gap-2'>
            <div className='flex flex-col items-center sm:items-start gap-y-1 w-full sm:w-[80%]'>
              <img src={logo} alt='' className='w-auto h-[33px]' />
              <p className="text-xs text-center sm:text-left text-black">
                Jora is an all-in-one platform that connects artists, venues, and crew for seamless live music event management and discovery.
              </p>
            </div>
            <div className='flex justify-center items-center w-full sm:w-[20%]'>
              <button onClick={() =>
              {
                setActivePage(PAGE_STATE.HOME);
                handleScrollTo(stayTunedRef);
              }}
                className="bg-[#4F46E5] text-white px-4 py-2 rounded-md hover:bg-purple-700 transition text-xs lg:text-sm">
                Sign up for beta
              </button>
            </div>
          </div>
          <div className='pt-5'>
            <p className="text-xs text-center md:text-left text-black">
              Copyright © 2024 Jora. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
