import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import App from './App';
import Events from './Events';
import OpenMic from './OpenMic';

export default function AppRouter()
{
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/event" element={<Events />} />
                <Route path="/openmic" element={<OpenMic />} />
            </Routes>
        </BrowserRouter>
    );
}
